import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const SeoDataFetcher = ({ url }) => {
    const [metaElements, setMetaElements] = useState([]);
    useEffect(() => {
        const fetchSeoData = async () => {
            try {
                const response = await fetch(`https://wordpress.myhealthinsurance.com/wp-json/rankmath/v1/getHead?url=${url}`);
                const data = await response.json();
                // console.log('Fetched SEO data:', data);
                if (data && data.head) {
                    // Perform search and replace in the entire HTML string
                    const oldUrl = 'https://wordpress.myhealthinsurance.com';
                    const newUrl = 'https://myealthinsurance.com';
                    data.head = data.head.replace(new RegExp(oldUrl, 'g'), newUrl);
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(data.head, 'text/html');
                    const titleElement = doc.querySelector('title');
                    const metaTags = Array.from(doc.querySelectorAll('meta'));
                    const jsonLdScriptElement = doc.querySelector('script.rank-math-schema-pro');
                    // Debugging logs
                    // console.log('Title:', titleElement ? titleElement.textContent : 'No title');
                    // console.log('Meta Tags:', metaTags);
                    // console.log('JSON-LD Script Element:', jsonLdScriptElement ? jsonLdScriptElement.outerHTML : 'No JSON-LD script');

                    setMetaElements([
                        titleElement ? <title key="title">{titleElement.textContent}</title> : null,
                        ...metaTags.map((meta, index) => {
                            const props = {};
                            for (let attr of meta.attributes) {
                                props[attr.name] = attr.value;
                            }
                            return <meta key={index} {...props} />;
                        }),
                    ]);
                    // Inject the JSON-LD script directly into the document head
                    if (jsonLdScriptElement) {
                        const script = document.createElement('script');
                        script.type = 'application/ld+json';
                        script.className = 'rank-math-schema-pro';
                        script.textContent = jsonLdScriptElement.textContent; // Use textContent instead of innerHTML
                        document.head.appendChild(script);
                    }
                }
            } catch (error) {
                console.error('Error fetching SEO data:', error);
            }
        };
        fetchSeoData();
    }, [url]);

    return (
        <Helmet>
            {metaElements}
        </Helmet>
    );
};
export default SeoDataFetcher;